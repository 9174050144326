// Whitepaper.js
import React from 'react';

const Whitepaper = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      {/* Embed the PDF from the public folder */}
      <embed
        src="/Kameleon_Whitepaper.pdf"  // PDF path from public folder
        type="application/pdf"
        width="80%"
        height="100%"
      />
    </div>
  );
};

export default Whitepaper;
